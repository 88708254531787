import { Order } from "@today/api/taker"
import { RegionSet } from "@today/api/tracker"
import { getCustomer } from "@today/lib"

export type AreaCode = "A" | "B" | "C" | "D" | "E" | "F"

export function getAreaCode(
  order: Order,
  regionSets?: RegionSet[]
): AreaCode | undefined {
  const regionId = getCustomer(order).lastMileInfo?.regionId
  const regionSet = regionSets
    ?.filter((rs) => rs.regionSetPolicyType === "TODAY_EVENING")
    .find((rs) => rs.regionIds.includes(regionId))
  const stationName = regionSet?.regionSetName.split("-")[0]
  switch (stationName) {
    case "계양":
      return "A"
    case "남양주":
      return "B"
    case "하남":
      return "C"
    case "K김포":
      return "F"
  }
}

import { Client, ListBillingsResponse } from "@today/api/taker"
import { useAuthentication, useUserInfo } from "@today/auth"
import { classNames } from "@today/lib"
import { AppNavBar } from "baseui/app-nav-bar"
import Link from "next/link"
import { useRouter } from "next/router"
import { PropsWithChildren } from "react"
import { ENV } from "../../config"
import { useSWRWithAuth } from "../../utils"

const ATOZ_CLIENT_IDS = ["162820667538", "559758330381"]
const FASTBOX_CLIENT_IDS = ["133449864958", "585576362589"]
const 전통시장_당일_CLIENT_IDS = [
  // 열우물전통시장상인회
  "111023062886",
  "516835624343",
  // 계산시장상인회
  "149800045542",
  "596583941405",
  // 소래포구어시장상인회
  "142744887591",
  "573041461801",
  // 부평문화의시장상인회
  "162980577426",
  "580673658985",
  // 석바위시장상인회
  "198151707466",
  "578743879866",
]
const 전통시장_익일_CLIENT_IDS = [
  // 열우물전통시장상인회
  "129121477054",
  "550729731557",
  // 계산시장상인회
  "117004514679",
  "504706660475",
  // 소래포구어시장상인회
  "165842670753",
  "586147074116",
  // 부평문화의시장상인회
  "137381706691",
  "548797062397",
  // 석바위시장상인회
  "192893259543",
  "539439591613",
]

export function NavBar() {
  const router = useRouter()
  const { isAuthenticated } = useAuthentication()
  const { clientId } = useUserInfo()
  const { logout } = useAuthentication()
  const { data: client } = useSWRWithAuth<Client>(
    clientId && `/api/clients/${clientId}`
  )
  const { data: billingsData } = useSWRWithAuth<ListBillingsResponse>(
    clientId && `/api/clients/${clientId}/billings?page_size=100`
  )
  const hasPendingBillings = billingsData?.billings?.some(
    (billing) =>
      ["READY", "WAITING_DEPOSIT"].includes(billing.state) ||
      (billing.state === "EXPIRED" && !billing.virtualAccount?.renewedBillingId)
  )

  const items: { label: string; route: string }[] = [
    ...(isAuthenticated ? [{ label: "배송관리", route: "/orders" }] : []),
    ...(client?.enableBilling
      ? [{ label: "결제센터", route: "/billings" }]
      : []),
    ...(clientId && ATOZ_CLIENT_IDS.includes(clientId)
      ? [{ label: "축산품 이력 관리", route: "/atoz" }]
      : []),
    ...(clientId && FASTBOX_CLIENT_IDS.includes(clientId)
      ? [{ label: "패스트박스", route: "/fastbox" }]
      : []),
    { label: "이용문의", route: "https://manual.amazing.today" },
    ...(!isAuthenticated && router.pathname === "/incheon"
      ? [{ label: "비밀번호 변경", route: "/incheon/reset-password" }]
      : []),
  ]
  return (
    <div className="bg-white">
      {ENV !== "prod" && (
        <AttentionRibbon className="bg-red-500">
          테스트용 환경({ENV.toUpperCase()})입니다.&nbsp;
          <a className="underline" href="https://partner.amazing.today">
            운영 환경 바로가기
          </a>
        </AttentionRibbon>
      )}
      {hasPendingBillings &&
        (!router.pathname.startsWith("/billings") ? (
          <AttentionRibbon className="bg-red-500">
            미결제건이 있습니다. 결제센터에서 처리 부탁드립니다.{" "}
            <Link href="/billings">
              <span className="underline">결제센터 바로가기</span>
            </Link>
          </AttentionRibbon>
        ) : (
          <AttentionRibbon className="bg-red-500">
            미결제건이 있습니다. 빠른 입금 부탁드립니다.
          </AttentionRibbon>
        ))}
      {clientId && 전통시장_당일_CLIENT_IDS.includes(clientId) && (
        <AttentionRibbon className="bg-blue-500">
          서울/인천 당일배송
        </AttentionRibbon>
      )}
      {clientId && 전통시장_익일_CLIENT_IDS.includes(clientId) && (
        <AttentionRibbon className="bg-green-500">전국 택배</AttentionRibbon>
      )}
      <AppNavBar
        title={<Link href="/orders">Today Partner</Link>}
        username={client?.name}
        mainItems={items.map(({ label, route }) => ({
          label,
          info: route,
          active: router.pathname === route,
        }))}
        onMainItemSelect={({ info }) => router.push(info)}
        userItems={
          client
            ? [
                ...(client.conditions.some(
                  (condition) => condition.policyType === "SME"
                )
                  ? [
                      {
                        label: "비밀번호 변경",
                        info: "/incheon/reset-password",
                      },
                    ]
                  : []),
                { label: "로그아웃", info: "/logout" },
              ]
            : []
        }
        onUserItemSelect={(item) => {
          if (item.info === "/logout") {
            logout()
          } else {
            router.push(item.info)
          }
        }}
      />
    </div>
  )
}

function AttentionRibbon({
  className,
  children,
}: PropsWithChildren<{
  className: string
}>) {
  return (
    <div
      className={classNames(
        "py-1 text-center text-sm font-semibold text-white",
        className
      )}
    >
      {children}
    </div>
  )
}

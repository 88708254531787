import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { UserInfo } from "@today/auth/keycloak"
import { useRouter } from "next/router"
import jwt from "jsonwebtoken"
import { Modal } from "./Modal"
import { Button } from "./Button"

type KioskUserInfo = UserInfo & {
  accessToken?: string
}

// XXX: 키오스크의 경우 키클락 로그인 폼이 아닌 앱내 로그인 폼을 사용하므로 keycloak-js를 사용할 수 없음. 따라서 커스텀 UserInfo 사용
export const defaultUserInfo = () => ({
  isAuthenticated: false,
  name: "",
  email: "",
  realmRoles: [],
  clientRoles: [],
  outsourcingOrganizationId: undefined,
  clientId: "",
  accessToken: "",
})

const UserInfoContext = createContext<{
  userInfo: KioskUserInfo
  setUserInfo: (
    value: ((prevState: UserInfo) => UserInfo) | UserInfo | null
  ) => void
}>({
  userInfo: defaultUserInfo(),
  setUserInfo: () => {},
})

export function KioskUserInfo({ children }: PropsWithChildren<any>) {
  const [userInfo, setUserInfo] = useState<KioskUserInfo>(defaultUserInfo())
  const [showMinuteLeftModal, setShowMinuteLeftModal] = useState(false)
  const [showInitializedMoadl, setShowInitializedMoadl] = useState(false)
  const router = useRouter()

  const logoutWarnTimer = useRef<any | null>(null)
  const logoutTimer = useRef<any | null>(null)

  const resetTimer = () => {
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current)
    }
    if (logoutWarnTimer.current) {
      clearTimeout(logoutWarnTimer.current)
    }
    if (router.pathname === "/kiosk") {
      return
    }
    logoutTimer.current = setTimeout(() => {
      setShowInitializedMoadl(true)
      setShowMinuteLeftModal(false)
      handleSetUserInfo(null)
      router.push("/kiosk")
      setTimeout(() => {
        setShowInitializedMoadl(false)
        setShowMinuteLeftModal(false)
      }, 10000)
    }, 180000)
    logoutWarnTimer.current = setTimeout(() => {
      setShowMinuteLeftModal(true)
      setShowInitializedMoadl(false)
    }, 120000)
  }

  useEffect(() => {
    window.addEventListener("click", resetTimer)
    window.addEventListener("mousemove", resetTimer)
    window.addEventListener("keydown", resetTimer)
    window.addEventListener("scroll", resetTimer)
    resetTimer()
    return () => {
      window.removeEventListener("click", resetTimer)
      window.removeEventListener("mousemove", resetTimer)
      window.removeEventListener("keydown", resetTimer)
      window.removeEventListener("scroll", resetTimer)
      if (logoutTimer.current) {
        clearTimeout(logoutTimer.current)
      }
      if (logoutWarnTimer.current) {
        clearTimeout(logoutWarnTimer.current)
      }
    }
  }, [userInfo, router.pathname])
  const handleSetUserInfo = (accessToken: any) => {
    if (!accessToken) {
      setUserInfo(defaultUserInfo())
      return
    }
    const jwtObject = jwt.decode(accessToken) as any
    setUserInfo({
      isAuthenticated: true,
      name: jwtObject.name,
      email: jwtObject.email,
      realmRoles: jwtObject.realm_access?.roles,
      clientRoles: jwtObject.resource_access?.[jwtObject.azp]?.roles,
      outsourcingOrganizationId: jwtObject.outsourcing_organization_id,
      clientId: jwtObject.client_id,
      accessToken: accessToken,
    })
  }
  return (
    <UserInfoContext.Provider
      value={{ userInfo: userInfo, setUserInfo: handleSetUserInfo }}
    >
      {children}
      <Modal
        open={showMinuteLeftModal}
        onClose={() => setShowMinuteLeftModal(false)}
      >
        {
          <>
            {userInfo.isAuthenticated ? (
              <p className="py-12 text-center text-5xl font-bold leading-tight">
                1분 후에 로그아웃 될거예요.
                <br />
                계속 이용하시려면 화면을 터치해 주세요!
              </p>
            ) : (
              <p className="py-12 text-center text-5xl font-bold leading-tight">
                1분 후에 홈 화면으로 돌아갈 거예요.
                <br />
                계속 이용하시려면 화면을 터치해 주세요!
              </p>
            )}
            <Button
              onClick={() => {
                setShowMinuteLeftModal(false)
              }}
            >
              계속 이용하기
            </Button>
          </>
        }
      </Modal>
      <Modal
        open={showInitializedMoadl}
        onClose={() => setShowInitializedMoadl(false)}
      >
        {
          <>
            {userInfo.isAuthenticated ? (
              <p className="py-12 text-center text-5xl font-bold leading-tight">
                아무런 활동이 없어서 <br />
                로그아웃됐어요.
              </p>
            ) : (
              <p className="py-12 text-center text-5xl font-bold leading-tight">
                아무런 활동이 없어서 <br />홈 화면으로 돌아갔어요.
              </p>
            )}
            <Button
              onClick={() => {
                setShowInitializedMoadl(false)
              }}
            >
              확인
            </Button>
          </>
        }
      </Modal>
    </UserInfoContext.Provider>
  )
}

export function useKioskUserInfo() {
  return useContext(UserInfoContext)
}
